<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{title}}</ion-title>
      <a class="app-logo" href="https://www.buymeacoffee.com/honeydoodat">
        <ion-img src="/assets/images/HoneyDooDat_logo.png"></ion-img>
      </a>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { IonHeader, IonToolbar, IonTitle, IonImg } from '@ionic/vue';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: 'Tabs',
  components: { IonHeader, IonToolbar, IonTitle, IonImg },
  props: {
    title: String
  }
})
</script>

<style scoped>
  .app-logo {
    position: absolute;
    top: 0;
    right: 1px;
  }
  .app-logo ion-img {
    width: 60px;
    height: 30px;
  }
</style>