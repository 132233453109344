
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonRange,
  IonButton
} from '@ionic/vue';
import Header from '@/components/Header.vue';
import { getImageSettingsAsync, ImageSetting, saveImageSettingsAsync } from '@/composables/imageSettings';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {
    Header,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonRange,
    IonButton
  },
  data() {
    return {
      images: ([] as ImageSetting[])
    }
  },
  async mounted() {
    this.initImages();
  },
  methods: {
    async initImages() {
      this.images = await getImageSettingsAsync();
    },
    async save() {
      await saveImageSettingsAsync(this.images);
      this.$router.push('/tabs/slaptrack');
    }
  }
});
