
import { IonHeader, IonToolbar, IonTitle, IonImg } from '@ionic/vue';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: 'Tabs',
  components: { IonHeader, IonToolbar, IonTitle, IonImg },
  props: {
    title: String
  }
})
