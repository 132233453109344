import { Plugins, Storage } from '@capacitor/core';

const IMAGE_SETTING_STORAGE = 'image_settings';

const imgRoot = '/assets/images/';
const imgNames = [
    'Chest_Left', 'Chest_Right', 'Clap', 'Improvise', 'Pat', 'Rest', 'Snap', 'Stamp', 'Stomp'
];

export class ImageSetting {
    get percentage(): number {
        return this.rate * 100;
    }

    set percentage(p: number) {
        this.rate = p / 100;
    }

    get path(): string {
        return `${imgRoot}${this.name}.png`;
    }

    constructor(public name: string, public rate: number) { }
}

export async function saveImageSettingsAsync(imagesSelected: ImageSetting[]): Promise<void> {
    const { Storage } = Plugins;
    await Storage.set({
        key: IMAGE_SETTING_STORAGE,
        value: JSON.stringify(imagesSelected)
    });
}

export async function getImageSettingsAsync(): Promise<ImageSetting[]> {
    const defaults = imgNames.map(x => new ImageSetting(x, 1));
    const imageJson = await Storage.get({ key: IMAGE_SETTING_STORAGE });
    if (imageJson.value) {
        const images: ImageSetting[] = JSON.parse(imageJson.value);
        return defaults.map(d => {
            const setting = images.find(x => x.name == d.name);
            if (setting) {
                if (setting.rate === undefined) {
                    d.rate = (setting as any).enabled ? 1 : 0;
                }
                else {
                    d.rate = setting.rate;
                }
            }
            return d;
        })
    }
    return defaults;
}

export async function getEnabledImagePathsAsync(): Promise<ImageSetting[]> {
    const images = await getImageSettingsAsync();
    return images
        .filter(x => x.rate > 0);
}
