<template>
  <ion-page>
    <Header title="Settings" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Settings</ion-title>
        </ion-toolbar>
      </ion-header>

      <h1>Select images to use:</h1>
      <ion-button color="primary" @click="save()">Save</ion-button>
      
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-md="6" size-lg="3" size-xl="4" v-for="img in images" :key="img.name">
            <ion-card>
              <img :src="img.path" class="app-image-select" />
              <ion-card-header>
                <ion-label>
                  <h2>{{ img.name.replace('_', ' ') }}</h2>
                </ion-label>
              </ion-card-header>
              <ion-card-content>
                <ion-label>Rate of Appearance:</ion-label>
                <ion-range v-model="img.percentage" min="0" max="100" step="10" snaps="true" ticks="true" color="secondary">                
                </ion-range>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonRange,
  IonButton
} from '@ionic/vue';
import Header from '@/components/Header.vue';
import { getImageSettingsAsync, ImageSetting, saveImageSettingsAsync } from '@/composables/imageSettings';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {
    Header,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonRange,
    IonButton
  },
  data() {
    return {
      images: ([] as ImageSetting[])
    }
  },
  async mounted() {
    this.initImages();
  },
  methods: {
    async initImages() {
      this.images = await getImageSettingsAsync();
    },
    async save() {
      await saveImageSettingsAsync(this.images);
      this.$router.push('/tabs/slaptrack');
    }
  }
});
</script>

<style scoped>
  .app-flexlist {
    display: flex;
  }
  .app-image-select {
    width: 200px;
  }
</style>